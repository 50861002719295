<template>
  <div class="navibar">
    <el-row>
      <el-col :span="8" class="hidden-xs-only">
        <div class="grid-content">
          <img src="../assets/logo.png" class="img1">
        </div>
      </el-col>
      <el-col :span="8" class="hidden-sm-and-up">
        <div class="grid-content">
          <img src="../assets/logo2.png" class="img2">
        </div>
      </el-col>
      <el-col :span="14" class="hidden-md-and-down">
        <div class="grid-content">
          <div class="menu">
            <el-row>
              <el-col v-for="(e,i) in navList" :key="i" :span="3">
                <div :class="['menus',{'iscurrent':e.iscurrent}]" @click="choose(i)">
                  {{ e.cname }}
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
      <el-col :span="1" class="hidden-lg-and-up"  :offset="22">
        <div :class="['icons',{'roll':showList}]">
          <svg class="icon" width="25" height="25" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-042ca774=""
                 @click="clickIcon"
            ><path fill="currentColor" d="M128 192h768v128H128V192zm0 256h512v128H128V448zm0 256h768v128H128V704zm576-352l192 160-192 128V352z"></path></svg>
        </div>
      </el-col>
    </el-row>
    <div class="dropDwonMenu">
      <el-collapse-transition>
        <el-row v-if="showList"  class="hidden-lg-and-up">
          <template v-for="(e,i) in navList" :key="i">
            <drop-item :cname="e.cname" :iscurrent="e.iscurrent" @click="choose(i)"></drop-item>
          </template>
        </el-row>
      </el-collapse-transition>
    </div>
  </div>
</template>

<script>
import 'element-plus/theme-chalk/display.css'
import dropItem from "@/components/dropItem";
import { mapState,mapActions } from 'vuex'
import { changeNav } from "@/utils/navListState";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "navibar",
  components:{
    dropItem
  },
  data(){
    return {
      showList: false
    }
  },
  computed:{
    ...mapState({
      navList: "navList",
      navState: "navState"
    })
  },
  methods: {
    choose(r){
      document.body.scrollTop = document.documentElement.scrollTop = 0
      this.showList = this.showList === false ? true : false
      if(r == 0){
        this.$router.push({
          name:'home'
        })
      }else{
        this.$router.push({
          name:'listpage'
        })
      }
      changeNav(r)
    },
    // 右上角小角标的点击
    clickIcon(){
      this.showList = this.showList === false ? true : false
    },
    ...mapActions({
      getList: 'getListforList',
      getHomelist: 'getList'
    })
  },
  watch:{
    navState(n){
      this.$store.state.showloading = true
      this.$store.state.current = 1
      this.$store.state.newsLists.current = 1
      setTimeout(()=>{
        this.$store.state.showloading = false
        console.log('我来自navibar，已发请求，id为：' + n +',路由为'+ this.$route.path);
        if(this.$route.path == '/listpage'){
          if(this.navState<8){
            this.$store.state.newsLists.type = n
            this.getList(n)
          }
        }else if(this.$route.path == '/'){
          // 刷新时如果网速不达标，直接回首页
          this.$router.push('/')
          this.getHomelist()
        }
      },500)
    }
  },
  beforeMount() {
    // 防止刷新后不记录导航状态
    this.$store.state.navState = sessionStorage.getItem('navState') ? sessionStorage.getItem('navState') : 0
    this.navList.forEach((e,i)=>{
      this.$store.state.navList[i].iscurrent = i == this.navState ? true : false
    })
  }
}
</script>

<style scoped lang="scss">
.navibar{
  top: 0;
  position: fixed;
  z-index: 100;
  width: 100%;
  background-color: #fff;
  height: 80px;
}
.img1{
  margin-top: 20px;
  margin-left: 7%;
}
.img2{
  margin-top: 5px;
  margin-left: 7%;
}
.menus{
  color: #090909;
  box-sizing: border-box;
  font-size: 14px;
  text-align: center;
  height: 80px;
  line-height: 80px;
  border-bottom: 3px solid rgba(211,74,74,0);
  cursor: pointer;
  &:hover{
    color: rgba(211,74,74,1);
  }
}
div.iscurrent{
  color: rgba(211,74,74,1);
  border-bottom: 2px solid rgba(211,74,74,1);
}
.icons{
  position: absolute;
  top: 30px;
  transition: all 0.3s;
  &.roll{
    transform: rotate(90deg);
  }
  &:hover{
    cursor: pointer;
  }
}
.dropDwonMenu{
  width: 100%;
  position: absolute;
  z-index: 100;
  background-color: #fff;
}
@media screen and (max-width: 768px) {
  .navibar{
    height: 40px;
  }
  .icons{
    position: absolute;
    top: 8px;
    transition: all 0.3s;
    &.roll{
      transform: rotate(90deg);
    }
    &:hover{
      cursor: pointer;
    }
  }
}
</style>