import { createRouter, createWebHistory } from 'vue-router'
import Home from "../views/Home";

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/listpage',
    name: 'listpage',
    component: () => import('../views/ListPage')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/Detail')
  },
  {
    path: '/enterprisehonor',
    name: 'enterprisehonor',
    component: () => import('../views/EnterpriseHonor')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
