<template>
  <div class="Home">
    <banner></banner>
    <pro-show></pro-show>
    <new-center></new-center>
  </div>
</template>

<script>
import banner from "@/components/banner";
import proShow from "@/components/proShow";
import newCenter from "@/components/newCenter";
import {changeNav} from "@/utils/navListState";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
  components:{
    banner,
    proShow,
    newCenter,
  },
  beforeRouteEnter(to,from,next){
    next(()=>{
      changeNav(0)
      document.body.scrollTop = document.documentElement.scrollTop = 0
    })
  }
}
</script>

<style scoped lang="scss">

</style>