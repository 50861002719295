<template>
  <div class="proShow">
    <el-row>
      <el-col :md="6" :sm="12" @click="cl(1)">
        <div class="f1">
          <div>
            <svg class="icon" width="50" height="50" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-042ca774=""><path fill="currentColor" d="M192 128v704h384V128H192zm-32-64h448a32 32 0 0132 32v768a32 32 0 01-32 32H160a32 32 0 01-32-32V96a32 32 0 0132-32z"></path><path fill="currentColor" d="M256 256h256v64H256v-64zm0 192h256v64H256v-64zm0 192h256v64H256v-64zm384-128h128v64H640v-64zm0 128h128v64H640v-64zM64 832h896v64H64v-64z"></path><path fill="currentColor" d="M640 384v448h192V384H640zm-32-64h256a32 32 0 0132 32v512a32 32 0 01-32 32H608a32 32 0 01-32-32V352a32 32 0 0132-32z"></path></svg>
          </div>
          <div>关于坤唐</div>
        </div>
      </el-col>
      <el-col :md="6" :sm="12" @click="cl(2)">
        <div class="f2">
          <div><svg class="icon" width="50" height="50" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-042ca774=""><path fill="currentColor" d="M359.168 768H160a32 32 0 01-32-32V192H64a32 32 0 010-64h896a32 32 0 110 64h-64v544a32 32 0 01-32 32H665.216l110.848 192h-73.856L591.36 768H433.024L322.176 960H248.32l110.848-192zM832 192H192v512h640V192zM342.656 534.656a32 32 0 11-45.312-45.312L444.992 341.76l125.44 94.08L679.04 300.032a32 32 0 1149.92 39.936L581.632 524.224 451.008 426.24 342.656 534.592z"></path></svg></div>
          <div>项目管理</div>
        </div>
      </el-col>
      <el-col :md="6" :sm="12" @click="cl(3)">
        <div class="f3">
          <div><svg class="icon" width="50" height="50" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-042ca774=""><path fill="currentColor" d="M288 128h608L736 384l160 256H288v320h-96V64h96v64z"></path></svg></div>
          <div>企业文化</div>
        </div>
      </el-col>
      <el-col :md="6" :sm="12" @click="cl(4)">
        <div class="f4">
          <div><svg class="icon" width="50" height="50" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-042ca774=""><path fill="currentColor" d="M576 128v288l96-96 96 96V128h128v768H320V128h256zm-448 0h128v768H128V128z"></path></svg></div>
          <div>企业荣誉</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {changeNav} from "@/utils/navListState";

export default {
  name: "proShow",
  methods:{
    cl(i){
      switch (i){
        case 1:
          this.$router.push('/listpage')
          changeNav(7)
              break
        case 2:
          this.$router.push('/listpage')
          changeNav(3)
          break
        case 3:
          this.$router.push('/listpage')
          changeNav(5)
          break
        case 4:
          this.$router.push({path:'/enterprisehonor'})
          changeNav(7)
          break
      }
    }
  }
}
</script>

<style scoped lang="scss">
@media screen and  (max-width: 992px)
{
  .f1,.f2,.f3,.f4{
    >div{
      margin-top: 15px;
    }
    height: 150px;
    padding-top: 30px;
  }
}
@media screen and  (max-width: 768px)
{
  .f1,.f2,.f3,.f4{
    height: 100px;
    padding-top: 10px;
    >div{
      margin-top: 1px;
    }
  }
}
@media screen and  (min-width: 993px)
{
  .f1,.f2,.f3,.f4{
    height: 247px;
    padding-top: 60px;
    >div{
      margin-top: 20px;
    }
  }
}
.proShow{
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  .f1,.f3{
    box-sizing: border-box;
    background-color: #a3150c;
    //height: 247px;
  }
  .f2,.f4{
    box-sizing: border-box;
    background-color: #e17d77;
    //height: 247px;
  }
  .f1,.f2,.f3,.f4{
    & div,& svg{
      transition: 0.3s;
    }
    &:hover{
      & div,& svg{
        transform: scale(1.1);
      }
    }
  }
}
</style>