<template>
  <div :class="['dropItem',{iscurrent:iscurrent}]">
    {{ cname }}
  </div>
</template>

<script>
export default {
  name: "dropItem",
  props:{
    cname:{
      type: String,
      default: '首页'
    },
    iscurrent:{
      type:Boolean,
      default: false
    }
  },
  methods:{

  }
}
</script>

<style scoped lang="scss">
.dropItem{
  padding: 0 15px;
  text-align: center;
  font-weight: bold;
  width: 100%;
  height: 50px;
  font-size: 14px;
  line-height: 50px;
  border-bottom: 1px solid rgba(211,74,74,0);
  cursor: pointer;
  &:hover{
    color: rgba(211,74,74,1);
    border-bottom: 2px solid rgba(211,74,74,1);
  }
}
</style>