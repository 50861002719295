<template>
  <div class="banner" :style="styleObj">
    <el-row>
      <el-col :span="24">
        <div class="bg" :style="styleObj">

        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "banner",
  data(){
    return {
      // w/h =  16/9  ===>  w = h * (16/9)
      styleObj: {
        height:'',
        width: '',
        backgroundPositionY: ''
      }
    }
  },
  beforeMount() {
    this.styleObj.height = (document.body.clientWidth)/(26/9) + 'px'
    this.styleObj.backgroundPositionY = -parseInt(this.styleObj.height) / 3 + 'px'
    this.styleObj.width = '100%'
    window.onresize=()=>{
      this.styleObj.height = (document.body.clientWidth)/(26/9) + 'px'
      this.styleObj.backgroundPositionY = -parseInt(this.styleObj.height) / 3 + 'px'
      this.styleObj.width = parseInt(this.styleObj.height) * (26/9) + 'px'
    }
  }
}
</script>

<style scoped lang="scss">
@keyframes changebg
{
  0% {
    background-image: url("../assets/1.jpg");
  }
  32% {
    background-image: url("../assets/1.jpg");
  }
  33% {
    background-image: url("../assets/2.jpg");
  }
  64% {
    background-image: url("../assets/2.jpg");
  }
  66%{
    background-image: url("../assets/3.jpg");
  }
  98%{
    background-image: url("../assets/3.jpg");
  }
  100%{
    background-image: url("../assets/1.jpg");
  }
}
.banner{
  margin-top: 80px;
}
.bg{
  width: 100%;
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100% auto;
  background-clip: border-box;
  top: 0;
  left: 0;
  animation: ease-in-out changebg 20s infinite;
  overflow: hidden;
}
@media screen and  (max-width: 768px){
  .banner{
    margin-top: 40px;
  }
}
</style>