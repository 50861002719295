<template>
  <div class="footBar">
    <el-row>
      <el-col  :md="{span:6, offset:2}" :sm="{span:24, offset:0}">
        <div class="title">
          坤唐建设集团有限公司
        </div>
        <div class="des">
          四川坤唐建设集团有限公司成立于2009年3月，目前注册资金 3亿元。公司实行董事会领导下的总经理负责制，管理层分为决策层、经营层和执行层。公司始终坚持弘扬“不求妄大，但求本真”的企业品格；秉承“厚德至诚、创新致远、励志卓越”的理念，以“愿景吸引人，文化引领人，绩效激励人，事业成就人”的用人机制，在激烈的市场竞争中，稳步发展，逐渐壮大。
        </div>
        <div class="dian">
          ……………………
        </div>

      </el-col>
      <el-col  :md="{span:6, offset:2}" :sm="{span:24, offset:0}">
        <div class="erweim">
          <img src="../assets/erweima.png" alt="">
        </div>
<!--        <div class="title">-->
<!--          底部导航栏-->
<!--        </div>-->
<!--        <div class="des">-->
<!--          <div class="item" v-for="(e,i) in navList" :key="i" @click="cl(i)">-->
<!--            > {{ e.cname }}-->
<!--          </div>-->
<!--        </div>-->
      </el-col>
      <el-col  :md="{span:6, offset:0}" :sm="{span:24, offset:0}">
        <div class="title">
          联系我们
        </div>
        <div class="des">
          <div class="addr">地址：成都市武侯区一环路西一段菊乐路口1栋4层2号 <br><br>邮编：610000</div>
          <div class="number">电话：028-85065999</div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="bottom">
          <div class="des">
            Copyright © 2015 www.cdtpxs.com All Rights Reserved</div>
          <div class="des">
            本站涵盖的内容、图片、视频等模板演示数据，部分未能与原作者取得联系。若涉及版权问题，请及时通知我们并提供相关证明材料，我们将立即予以删除！ <br>
            版权声明：本站涵盖的版面设计，html、css、js代码等为权利所有人所有，盗版、翻版必究其法律责任。
          </div>
          <div class="des">版权所有：坤唐建设集团有限公司  2008-2021 备案号:<a href="https://beian.miit.gov.cn" target="blank"
            >蜀ICP备2020027171号</a></div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "footBar",
  data(){
    return {
      navList: [{
        cname: '首页',
        ename: 'HOME',
        iscurrent: true
      },{
        cname: '新闻中心',
        ename: 'NEWS',
        iscurrent: false
      },{
        cname: '市场拓展',
        ename: 'MARKET',
        iscurrent: false
      },{
        cname: '工程管理',
        ename: 'PROJECT',
        iscurrent: false
      },{
        cname: '人力资源',
        ename: 'RECUIT',
        iscurrent: false
      },{
        cname: '企业文化',
        ename: 'CULTURE',
        iscurrent: false
      },{
        cname: '党群视窗',
        ename: 'PARTY',
        iscurrent: false
      },{
        cname: '关于我们',
        ename: 'ABOUT US',
        iscurrent: false
      }]
    }
  },
  methods: {
    cl(i){
      console.log(this.navList[i].cname);
    }
  }
}
</script>

<style scoped lang="scss">
.footBar{
  margin-top: 60px;
  background-color: #a3150c;
  .title{
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    margin: 30px 0 30px 30px;
  }
  a{
    color: #fdcecb;
  }
  .des{
    margin: 0 0 0 30px;
    font-size: 16px;
    color: #fdcecb;
    padding-right: 30px;
  }
  .dian{
    color: #fff;
    margin: 0 0 0 30px;
  }
  .erweim{
    margin: 80px 0 30px 30px;
  }
  .item{
    margin-top: 4px;
    cursor: pointer;
  }
  .addr{
    margin: 15px 0;
  }
  .bottom{
    text-align: center;
    padding-bottom: 30px;
    >.des{
      padding-right: 30px;
    }
  }
}
</style>