<template>
  <navibar></navibar>
  <router-view/>
  <foot-bar></foot-bar>
</template>

<script>
import navibar from "@/components/navibar";
import footBar from "@/components/footBar";
import {mapState} from "vuex";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
  components:{
    navibar,
    footBar
  },
  computed:{
    ...mapState({
      showloading: 'showloading'
    })
  }
}
</script>
<style lang="scss">
#app{
  background-color: rgb(242, 244, 248);
}
body{
  margin: 0;
}
</style>
