// eslint-disable-next-line no-unused-vars
import store from "@/vuex";
function changeNav(i){
    sessionStorage.setItem('navState', i)
    store.state.navState = i
    store.state.navList.forEach((e,i)=>{
        e.iscurrent = i ==  store.state.navState ? true : false
    })
}

export {changeNav}