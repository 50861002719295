<template>
  <div class="newCenter">
    <el-row>
      <el-col :span="24">
        <div class="title">新闻中心</div>
        <div class="title_en"> —— News Center ——</div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="6" :sm="12">
        <div class="new_item" @click="clnew(newList[0].id,newList[0].type)">
          <div class="des">
            <div class="time">
              {{ newList[0].time }}
            </div>
            <div class="new_title">
              {{ newList[0].title }}
            </div>
            <div class="new_detail">
              {{ newList[0].detail }}
            </div>
          </div>
          <div class="image">
            <img :src="newList[0].url">
          </div>
        </div>
      </el-col>
      <el-col :md="6" :sm="12">
        <div class="new_item" @click="clnew(newList[1].id,newList[1].type)">
          <div class="des">
            <div class="time">
              {{ newList[1].time }}
            </div>
            <div class="new_title">
              {{ newList[1].title }}
            </div>
            <div class="new_detail">
              {{ newList[1].detail }}
            </div>
          </div>
          <div class="image">
            <img :src="newList[1].url">
          </div>
        </div>
      </el-col>
      <el-col :md="6" :sm="12">
        <div class="new_item" @click="clnew(newList[2].id,newList[2].type)">
          <div class="des">
            <div class="time">
              {{ newList[2].time }}
            </div>
            <div class="new_title">
              {{ newList[2].title }}
            </div>
            <div class="new_detail">
              {{ newList[2].detail }}
            </div>
          </div>
          <div class="image">
            <img :src="newList[2].url">
          </div>
        </div>
      </el-col>
      <el-col :md="6" :sm="12">
        <div class="new_item" @click="clnew(newList[3].id,newList[3].type)">
          <div class="des">
            <div class="time">
              {{ newList[3].time }}
            </div>
            <div class="new_title">
              {{ newList[3].title }}
            </div>
            <div class="new_detail">
              {{ newList[3].detail }}
            </div>
          </div>
          <div class="image">
            <img :src="newList[3].url">
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div class="more" @click="cl">
           查看更多 >
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { changeNav } from "@/utils/navListState";

export default {
  name: "newCenter",
  methods:{
    ...mapActions([
        // 'getList'
    ]),
    // 查看更多
    cl(){
      this.$router.push({path:'/listpage'})
      changeNav(1)
      document.body.scrollTop = document.documentElement.scrollTop = 0
    },
    clnew(i,type){
      this.$router.push(
          {name:'detail',params:{id:i,type:type}}
      )
    },
  },
  computed:{
    ...mapState({
      newList: 'newsList',
      navList: 'navList',
      navState: 'navState'
    })
  }
}
</script>

<style scoped lang="scss">
.el-col{
  overflow: hidden;
  text-overflow: ellipsis;
}
.newCenter{
  color: #090909;
  .title{
    font-weight: bold;
    font-size: 20px;
    margin-top: 20px;
    text-align: center;
    margin-top: 30px;
  }
  .title_en{
    text-align: center;
  }
  .time{
    font-size: 20px;
    color: rgb(136, 136, 136);
    margin: 30px;
  }
  .new_title{
    transition: 0.5s;
    position: relative;
    left: 0;
    color: rgb(51, 51, 51);
    overflow: hidden;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    margin: 30px;
  }
  .new_detail{
    min-height: 46.31px;
    position: relative;
    left: 0;
    transition: 0.8s;
    color: rgb(136, 136, 136);
    overflow: hidden;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    font-size: 12px;
    margin: 30px;
  }
  .image{
    margin-left: 30px;
    overflow: hidden;
     img{
      transition: 0.3s;
       width: 240px;
       height: 130px;
    }
  }
  .more{
    margin-top: 60px;
    text-align: center;
    cursor: pointer;
    &:hover{
      color: #a3150c;
    }
  }
  .new_item{
    &:hover{
      cursor: pointer;
      .new_title,.new_detail,.time{
        color: #e17d77;
      }
      .new_detail{
        left: 5px;
      }
      .new_title{
        left: 4px;
      }
      .image img{
        transform: scale(0.95);
      }
    }
  }
}
</style>