import {createStore} from 'vuex'
import axios from "axios";

// 创建一个新的 store 实例
const store = createStore({
    state() {
        return {
            navState: '0',   // 导航点击标签的记录
            current: 1,
            total: 0,
            showloading:true,
            navList: [
                {
                    cname: '首页',
                    ename: 'HOME',
                    iscurrent: true
                }, {
                    cname: '新闻中心',
                    ename: 'NEWS',
                    iscurrent: false
                }, {
                    cname: '市场拓展',
                    ename: 'MARKET',
                    iscurrent: false
                }, {
                    cname: '工程管理',
                    ename: 'PROJECT',
                    iscurrent: false
                }, {
                    cname: '人力资源',
                    ename: 'RECUIT',
                    iscurrent: false
                }, {
                    cname: '企业文化',
                    ename: 'CULTURE',
                    iscurrent: false
                }, {
                    cname: '党群视窗',
                    ename: 'PARTY',
                    iscurrent: false
                }, {
                    cname: '关于我们',
                    ename: 'ABOUT US',
                    iscurrent: false
                }
            ],  // 导航状态
            newsList: [
                {
                    time: '2002-02-13',
                    title: '坤唐新闻',
                    detail: '正在查询数据',
                    url: '/img.png'
                },
                {
                    time: '2002-02-13',
                    title: '坤唐新闻',
                    detail: '正在查询数据',
                    url: '/img.png'
                },
                {
                    time: '2002-02-13',
                    title: '坤唐新闻',
                    detail: '正在查询数据',
                    url: '/img.png'
                },
                {
                    time: '2002-02-13',
                    title: '坤唐新闻',
                    detail: '正在查询数据',
                    url: '/img.png'
                }
            ], // 首页使用
            newsLists: {
                current: 1,
                type:1,
                list:[
                    [{
                        time: '2002-02-13',
                        title: ' ',
                        detail: ' ',
                        url: '/img.png'
                    }, {
                        time: '2002-02-13',
                        title: ' ',
                        detail: ' ',
                        url: '/img.png'
                    }],
                    [{
                        time: '2002-02-13',
                        title: ' ',
                        detail: ' ',
                        url: '/img.png'
                    }, {
                        time: '2002-02-13',
                        title: ' ',
                        detail: ' ',
                        url: '/img.png'
                    }],
                    [{
                        time: '2002-02-13',
                        title: ' ',
                        detail: ' ',
                        url: '/img.png'
                    }, {
                        time: '2002-02-13',
                        title: ' ',
                        detail: ' ',
                        url: '/img.png'
                    }],
                ]
            } ,// 列表页使用
            about:{
                current: 1,
                type:1,
                list:[
                    [{
                        time: '2002-02-13',
                        title: ' ',
                        detail: ' ',
                        url: '/img.png'
                    }, {
                        time: '2002-02-13',
                        title: ' ',
                        detail: ' ',
                        url: '/img.png'
                    }],
                    [{
                        time: '2002-02-13',
                        title: ' ',
                        detail: ' ',
                        url: '/img.png'
                    }, {
                        time: '2002-02-13',
                        title: ' ',
                        detail: ' ',
                        url: '/img.png'
                    }],
                    [{
                        time: '2002-02-13',
                        title: ' ',
                        detail: ' ',
                        url: '/img.png'
                    }, {
                        time: '2002-02-13',
                        title: ' ',
                        detail: ' ',
                        url: '/img.png'
                    }],
                ]
            }
        }
    },
    mutations: {
        changeListforHome(state, data) {
            data.forEach((e, i) => {
                state.newsList[i] = e
            })
            setTimeout(()=>{
                state.showloading = false
            },200)
        },
        changeListforList(state, data) {
            if(state.navState<7){
                state.newsLists.type = data.type
                state.newsLists.current = state.current = data.current
                state.newsLists.total = state.total = data.total
                state.newsLists.list = []
                for(let i=0;i<data.list.length;i=i+2){
                    if(i>9){
                        break
                    }
                    const arr = []
                    arr[0] = data.list[i]
                    if(data.list[i+1]){
                        arr[1] = data.list[i+1]
                    }
                    state.newsLists.list.push(arr)
                }
                setTimeout(()=>{
                    state.showloading = false
                },200)
            }else{
                state.about.type = data.type
                state.about.current = state.current = data.current
                state.about.total = state.total = data.total
                state.about.list = []
                for(let i=0;i<data.list.length;i=i+2){
                    if(i>9){
                        break
                    }
                    const arr = []
                    arr[0] = data.list[i]
                    if(data.list[i+1]){
                        arr[1] = data.list[i+1]
                    }
                    state.about.list.push(arr)
                }
                setTimeout(()=>{
                    state.showloading = false
                },200)
            }
        }
    },
    actions: {
        // 获取首页新闻列表
        getList: (context) => {
            context.state.showloading = true
            axios.get('/api/getNews',{
                params:{
                    type: 0
                }
            }).then((response) => {
                const data = response.data.list
                context.commit('changeListforHome', data)
            })
        },
        getListforList: (context,type) => {
            context.state.showloading = true
            axios.get('/api/getNews',
                {
                    params: {
                        type: type,
                        current: context.state.newsLists.current || 1
                    }
                }
                ).then((response) => {
                const data = response.data
                context.commit('changeListforList', data)
            })
        }
    }
})

export default store
